import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider} from 'react-toast-notifications'
import './scss/style.scss';
import { API_BASE_URL, ACCESS_TOKEN_NAME, USERID, TOKEN_USER } from './constants/constant'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Home = React.lazy(() => import('./component/home'));
const UserLogin = React.lazy(() => import('./component/auth/login'));
const UserRegister = React.lazy(() => import('./component/auth/register'));
const VendorRegister = React.lazy(() => import('./component/auth/vendor-register'));
const UserBooking = React.lazy(() => import('./component/auth/booking'));
const UserAvailability = React.lazy(() => import('./component/auth/availability'));
const UserAppointment = React.lazy(() => import('./component/auth/appointment'));
const UserAppointmentReschedule = React.lazy(() => import('./component/auth/appointmentReschedule'));
const UserAppointmentConfirm = React.lazy(() => import('./component/auth/appointment-confirm'));
const RegistrationVendor = React.lazy(() => import('./component/auth/registration-vendor'));
const UserDashboard = React.lazy(() => import('./component/auth/dashboard'));
const UserCategory = React.lazy(() => import('./component/auth/category'));
const Pages = React.lazy(() => import('./component/auth/cmspages'));
const UserFurnitureStore = React.lazy(() => import('./component/auth/furniture-store'));
const VendorStore = React.lazy(() => import('./component/auth/vendor-stores'));
const AddVendorStore = React.lazy(() => import('./component/auth/newStore'));
const EditVendorStore = React.lazy(() => import('./component/auth/editVendorStore'));
const NearByStores = React.lazy(() => import('./component/auth/nearByStores'));
const UpdateProfile = React.lazy(() => import('./component/auth/updateProfile'));
const WorkspaceJitsiVideoModal = React.lazy(() => import('./component/auth/videoCall'));
const ViewDetails = React.lazy(() => import('./component/auth/viewDetails'));

class App extends Component {
  render() {
    
    return (
      
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Home" render={props => (TOKEN_USER)?<TheLayout {...props}/>:<Login {...props}/>} />
              <Route exact path="/user/dashboard" name="User Dashboard" render={props => <UserDashboard {...props}/>} />
              <Route exact path="/user/registration-vendor" name="Registration Vendor" render={props => <RegistrationVendor {...props}/>} />
              <Route exact path="/user/nearByStores" name="NearBy Stores" render={props => <NearByStores {...props}/>} />
              <Route exact path="/user/videocall" name="Video Call" render={props => <WorkspaceJitsiVideoModal {...props}/>} />
              <Route exact path="/user/stores/:id" name="User Furniture Store" render={props => <UserFurnitureStore {...props}/>} />
              <Route exact path="/user/vendor-stores/:id" name="Vendor Stores" render={props => <VendorStore {...props}/>} />
              <Route exact path="/user/update-profile/:id" name="Update Profile" render={props => <UpdateProfile {...props}/>} />
              <Route exact path="/user/edit-vendor-store/:id" name="Vendor Stores" render={props => <EditVendorStore {...props}/>} />
              <Route exact path="/user/add-store/:id" name="Vendor Store Add " render={props => <AddVendorStore {...props}/>} />
              <Route exact path="/user/appointment-confirm/" name="Appointment confirm" render={props => <UserAppointmentConfirm {...props}/>} />
              <Route exact path="/user/appointment/:id" name="Schedule Appointment" render={props => <UserAppointment {...props}/>} />
              <Route exact path="/user/appointment-reschedule/:id" name="Reschedule Appointment" render={props => <UserAppointmentReschedule {...props}/>} />
              <Route exact path="/user/category" name="User Category" render={props => <UserCategory {...props}/>} />
              <Route exact path="/pages/:id" name="Cms Pages" render={props => <Pages {...props}/>} />
              <Route exact path="/user/availability" name="Manage Availability" render={props => <UserAvailability {...props}/>} />
              <Route exact path="/user/booking" name="Booking" render={props => <UserBooking {...props}/>} />
              <Route exact path="/user/vendor-register" name="Vendor Register" render={props => <VendorRegister {...props}/>} />
              <Route exact path="/user/register" name="User Register" render={props => <UserRegister {...props}/>} />
              <Route exact path="/user/login" name="User Login" render={props => <UserLogin {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} />
              <Route exact path="/users" name="Users" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/partners" name="Partners" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/posts" name="Posts" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/addUser" name="Add User" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/editUser/:id" name="Edit User" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/:id" name="User Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/posts/addPost" name="Add Posts" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/posts/:id" name="Post Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/posts/editPost/:id" name="Edit Post" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/comments/:postId" name="Comments" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/replies/:postId/:commentId" name="Comments" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/comments/comment/:commentId" name="Comment" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors" name="Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/addVendors" name="Add Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/editVendor/:id" name="Edit Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/:id" name="Vendor Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores" name="Stores" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/addStore" name="Add Stores" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/storeBookings/:id" name="Store Bookings" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/:id" name="Store" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/editStore/:id" name="Edit Store" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers" name="Offers" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/addOffers" name="Add Offers" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/editOffer/:id" name="Edit Offer" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/:id" name="Offer" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/store-detail/:id" name="Store Details" render={props => <ViewDetails  {...props}/>} />
              <Route exact path="/brands" name="Brands" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/addBrand" name="Add Brands" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/editBrand/:id" name="Edit Brand" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/:id" name="Brand" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/addBanner" name="Add Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners" name="Banners" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/editBanner/:id" name="Edit Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/:id" name="Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages" name="Cms Pages" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/addPage" name="Add Cms Page" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/editPage/:id" name="Edit Page" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/:id" name="Page" render={props => <TheLayout  {...props}/>} />

              <Route exact path="/categories" name="Categories" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/categories/addCategory" name="Add Category" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/categories/:id" name="Category Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/categories/editCategory/:id" name="Edit Category" render={props => <TheLayout  {...props}/>} />

              <Route exact path="/emailtemplates" name="Email Templates" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/emailtemplates/add" name="Add Email Template" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/emailtemplates/:id" name="Email Template Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/emailtemplates/edit/:id" name="Edit Email Template" render={props => <TheLayout  {...props}/>} />

              <Route exact path="/faqs" name="Email Templates" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/faqs/add" name="Add Email Template" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/faqs/:id" name="Email Template Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/faqs/edit/:id" name="Edit Email Template" render={props => <TheLayout  {...props}/>} />
              
              <Route exact path="/topics" name="Topics" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/topics/addTopic" name="Add Topic" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/topics/:id" name="Topic Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/topics/editTopic/:id" name="Edit Topic" render={props => <TheLayout  {...props}/>} />
              
              <Route exact path="/partnerTypes/addPartnerType" name="Add Partner Type" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/partnerTypes" name="Partner Types" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/partnerTypes/editPartnerType/:id" name="Edit Partner Type" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/partnerTypes/:id" name="Partner Type" render={props => <TheLayout  {...props}/>} />
              
              <Route exact path="/eventTypes/addEventType" name="Add Event Type" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/eventTypes/editEventType/:id" name="Edit Event Type" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/eventTypes" name="Event Types" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/eventTypes/EventType/:id" name="Edit Event Type" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/eventTypes/:id" name="Event Type" render={props => <TheLayout  {...props}/>} />
              
              <Route exact path="/events/addEvent" name="Add Event" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/events/editEvent/:id" name="Edit Event" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/events" name="Events" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/events/bookings/:id" name="Bookings" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/events/:id" name="Event" render={props => <TheLayout  {...props}/>} />
              
              <Route exact path="/learnings/addLearning" name="Add Learn" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/learnings/editLearning/:id" name="Edit Learn" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/learnings" name="Learn" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/learnings/:id" name="Learn" render={props => <TheLayout  {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
      
    );
  }
}

export default App;
